<template>
  <section class="Category">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Edit Store</h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb p-0">
          <li class="breadcrumb-item">
            <router-link to="/catalog/stores" class="text-primary">Stores
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Edit Store</li>
        </ol>
      </nav>
    </div>
    <form class="pt-2" @submit.prevent="editStore">
      <div class="row" v-if="store">

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                </label>
                <div class="form-group">
                  <input type="text" v-model="store.name" id="name" name="name" class="form-control" :class="{
                    'is-invalid': submitted && $v.store.name.$error,
                  }" />
                  <div v-if="submitted && !$v.store.name.required" class="invalid-feedback">
                    Name is required
                  </div>
                </div>
              </div>
              <div class="form-group  mb-1">
                <label class=" m-0 p-0 fs-12 col-form-label" for="name">Sort </label>

                <div class="form-group">
                  <input type="number" v-model="store.sort" class="form-control" />
                </div>

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Address
                  </label>
                  <div class="form-group">
                    <textarea style="min-height: 75px;" class="form-control" rows="2" v-model="store.address" :class="{
                      'is-invalid': submitted && $v.store.address.$error,
                    }"></textarea>
                    <div v-if="submitted && !$v.store.address.required" class="invalid-feedback">
                      Address is required
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Status </label>
                <div class="form-group  ">
                  <toggle-button :value="true" v-model="store.active" :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="form-group  mb-1">
                <label class=" fs-12 p-0 m-0 col-form-label" for="name">Longitude & Latitude
                </label>
                <div class="row">
                  <div class="col-6 pr-1">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="long" />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="lat" />
                    </div>
                  </div>

                </div>
              </div>
              <div class="form-group  mb-1">

              </div>
              <div>
                <div class="form-group   mb-1">
                  <label class=" fs-12  p-0 m-0  col-form-label" for="name">Description
                  </label>
                  <div class="form-group">
                    <textarea class="form-control" rows="8" v-model="store.description"></textarea>
                  </div>

                </div>
              </div>
              <div class="form-group  ">
                <label for=""> Show Home </label>
                <div class="form-group  ">
                  <toggle-button :value="false" v-model="store.show_home"
                    :labels="{ checked: 'true', unchecked: 'false' }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group float-right mt-3">
            <button class="btn btn btn-add btn-primary">Update</button>
          </div>
        </div>
      </div>
    </form>



  </section>
</template>

<script>
import { catalogApi } from "../../../api";
import { required } from "vuelidate/lib/validators";
import Loader from "../../../components/Loader";
export default {
  name: "edit-store",
  props: {},
  components: {
    Loader,
  },
  data() {
    return {
      store: this.store,
      long: "",
      lat: "",
      submitted: false,
      showLoader: false,
    };
  },
  validations: {
    store: {
      name: { required },
      address: { required },
    },
  },
  mounted() {
    this.getStoreById();
  },
  methods: {
    async getStoreById() {
      this.showLoader = true;
      const { data } = await catalogApi.getStoresById(this.$route.params.id);
      this.stores = data.data.data;
      this.stores.forEach((value) => {
        this.store = value;
        if (this.store.coordinates) {
          let coordinates = JSON.parse(this.store.coordinates);
          coordinates.forEach((val) => {
            this.lat = val.lat;
            this.long = val.long;
          });
        }
        if (this.store.active === 1) {
          this.store.active = true;
        } else {
          this.store.active = false;
        }
      });
      this.showLoader = false;
    },

    async editStore() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.store.coordinates = JSON.stringify([
        { lat: `${this.lat}`, long: `${this.long}` },
      ]);

      if (this.store.active === true) {
        this.store.active = 1;
      } else {
        this.store.active = 0;
      }

      if (this.store.show_home === true) {
        this.store.show_home = 1;
      } else {
        this.store.show_home = 0;
      }

      for (var key in this.store) {
        if (this.store.hasOwnProperty(key)) {
          var val = this.store[key];
          if (val === null) {
            delete this.store[key];
          }
        }
      }


      this.showLoader = true;
      const data = await catalogApi.updateStore(this.$route.params.id, {
        ...this.store,
      });
      this.showLoader = false;
      this.$router.push("/catalog/stores");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
<style>
.form-group label {
  text-align: right;
}

.form-group label span {
  font-size: 10px;
}
</style>